const openButton = document.querySelector(".header__mob-open");
const closeButton = document.querySelector(".header__mob-close");
const menu = document.querySelector(".header__mob");
const overflow = document.querySelector(".header__mob-overflow");

openButton.addEventListener("click", () => {
  menu.classList.add("active");
  overflow.classList.add("active");
});

closeButton.addEventListener("click", () => {
  menu.classList.remove("active");
  overflow.classList.remove("active");
});

overflow.addEventListener("click", () => {
  menu.classList.remove("active");
  overflow.classList.remove("active");
});

document.addEventListener("click", e => {
  if (
    e.target.classList.contains("nav__item") ||
    e.target.closest(".nav__item") ||
    e.target.classList.contains("header__button") ||
    e.target.closest(".header__button")
  ) {
    menu.classList.remove("active");
    overflow.classList.remove("active");
  }
});
