const modalForm = (message) => {
  const openLink = document.querySelector(".modal-open");
  const modal = document.querySelector(".modal");
  const modalOverlay = document.querySelector(".modal-overlay");
  const modalText = document.querySelector(".modal__text");
  const pageBody = document.querySelector(".page__body");

  if (!modalText && !openLink && !modalOverlay) {
    return;
  }

  modalText.innerHTML = message;

  openLink.addEventListener("click", (e) => {
    e.preventDefault();
    modal.classList.add("modal--visible");
    modalOverlay.classList.add("modal-overlay--visible");
    pageBody.classList.add("stop-scrolling");

    setTimeout(() => {
      modalOverlay.classList.remove("modal-overlay--visible");
      modal.classList.remove("modal--visible");
      pageBody.classList.remove("stop-scrolling");
    }, 2000);
  });

  modalOverlay.addEventListener("click", ({ target }) => {
    if (target == modalOverlay) {
      modalOverlay.classList.remove("modal-overlay--visible");
      modal.classList.remove("modal--visible");
      pageBody.classList.remove("stop-scrolling");
    }
  });
};

modalForm();

jQuery(document).ready(function() {
  jQuery(".contact__form-button").click(function() {
    var form = jQuery(this).closest("form");

    var actUrl = form.attr("action");

    const modalSent = `
    Thank you for your message.
    <span>It has been sent.</span>
    `;

    const modalFailed = `
    The message hasn't
    <span>been sent</span>
    `;

    jQuery.ajax({
      url: actUrl,
      type: "post",
      dataType: "html",
      data: form.serialize(),
      success: function(data) {
        form.html(data);
        modalForm(modalSent);
        form.trigger("reset");
      },
      error: function() {
        modalForm(modalFailed);
      }
    });
  });
});
